#helpModal {
    a {
        text-decoration: underline;
    }
    h1,
    h2,
    h3 {
        font-size: 1.2em;
        padding: 0;
        margin: 0;
        line-height: 150%;
    }
    h3 {
        font-size: 1em;
    }
    p,
    li {
        line-height: 120%;
    }
    code,
    pre {
        font-size: 1em;
        white-space: break-spaces;
    }
}
