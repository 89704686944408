#media_wrapper {
	width: 100%;
	margin: 0 0 10px 0;
	padding: 10px;
	& .dimmer {
		& .loader {
			width: 50px;
			height: 50px;
		}
	}
	& #media_filter {
		max-width: 1200px;
		& form {
			& .field {
				@include respond(small) {
					padding: 0 5px;
				}
			}
			& .select2-container--default .select2-selection--single .select2-selection__arrow {
				top: 6px;
				right: 5px;
				pointer-events: none;
			}
			& .select2-results__group {
				font-weight: bold;
			}
			& #filter_buttons {
				margin: 10px 0;
				@include respond(small) {
					padding: 20px 5px 0 !important;
				}
				& button {
					width: 100%;
					font-size: 0.7em;
					text-transform: none;
					&:focus {
						color: $gac-grey6;
					}
				}
			}
		}
	}
	& #dam_assets {
		& .uk-grid-small {
			padding-left: 5px !important;
			@include respond(small) {
				padding-left: 0 !important;
			}
		}
		margin: 0;
		max-width: 1500px;
		& .asset_grid {
			&#mobile_grid {
				& .asset_wrapper {
					&:nth-child(odd) {
						padding-right: 2px;
					}
					&:nth-child(even) {
						padding-left: 2px;
					}
					& .media_image_wrapper {
						height: 120px;
					}
				}
			}

			& .asset_wrapper {
				&[data-type="PDF"] {
					& .media_image_wrapper {
						text-align: center;
						background-color: $gac-grey2;
						padding: 0px;
						@include respond(small) {
							&.large {
								padding: 70px;
							}
							&.small {
								padding: 30px;
							}
						}
						& img {
							box-shadow: 10px 10px 30px -20px $gac-black;
							height: 100% !important;
							width: auto !important;
							position: relative;
							display: block;
						}
					}
				}
			}
			// these classes are only in the desktop grids
			&#grid1,
			&#grid2 {
				margin-bottom: 150px;
			}
			& .large {
				height: 720px;
			}
			& .small {
				height: 320px;
			}
		}
		& .metadata_wrapper {
			line-height: 100%;
			margin: 5px 0 0;
			@include respond(small) {
				line-height: initial;
			}
			& .image_name_wrapper {
				font-size: 0.7em;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				@include respond(small) {
					max-width: 70%;
					font-size: 1em;
					&.incart {
						max-width: 60%;
					}
				}
				& .image_name {
					width: fit-content;
					position: relative;
					&[marquee] {
						cursor: pointer;
					}
				}
			}
			& .link_wrapper {
				font-size: 0.6em;
				margin-top: 3px;
				@include respond(small) {
					font-size: 0.7em;
				}
				& .add_link {
					cursor: pointer;
				}
			}
		}
	}

	& [data-section="media request handler"] {
		border: 1px solid #e7e7e7;
		margin: 30px 0;
		padding: 10px;
		overflow: auto;
		& #requestsHeader {
			position: relative;
			& .text {
				font-weight: bold;
				&:lang(zh-hans),
				:lang(zh-hant) {
					font-family: $font-family-cn !important;
					font-weight: 400;
				}
			}
			& [data-button="close request manager"] {
				position: absolute;
				right: 0;
			}
		}
		& [data-wrapper="user wrapper"] {
			margin-bottom: 30px;
			& .userDetails {
				background-color: $gac-steel;
				color: $gac-white;
				padding: 10px;
			}
			& .requestDetails {
				background-color: $gac-slate;
				color: $gac-white;
				padding: 5px;
				position: relative;
				& .request_details {
					margin: 20px 0 20px 10px;
				}
				& .requestItems {
					display: flex;
					flex-wrap: wrap;
					flex-direction: row;
					justify-content: space-evenly;
					& .asset_card {
						background-color: $gac-white;
						color: $gac-black;
						margin-bottom: 30px;
						width: 320px;
						& .asset_card_thumb_wrapper {
							height: 298px;
						}
					}
				}
				& [data-button="submit request response"] {
					margin: 0 auto 25px auto;
				}
				& #request_response_form_wrapper {
					width: 100%;
					height: 100%;
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					background-color: rgba(255, 255, 255, 0.9);
					z-index: 50;
					& form {
						width: 90%;
						padding: 10px;
						& .title {
							font-family: "Gulfstream Horizon", Arial, sans-serif;
							font-weight: bold;
							color: $gac-black;
							&:lang(zh-hans),
							:lang(zh-hant) {
								font-family: $font-family-cn !important;
								font-weight: 400;
							}
						}
						& textarea {
							width: 100%;
							-webkit-appearance: none;
							-moz-appearance: none;
							appearance: none;
							border: 1px solid $gac-grey6;
							margin: 0 0 10px 0;
							padding: 10px;
							font-size: 1em;
							color: rgba(0, 0, 0, 0.7);
						}
						& textarea:placeholder {
							color: rgba(0, 0, 0, 0.3);
						}
					}
				}
			}
		}
	}

	& #asset_header {
		text-align: left;
		margin: 0 0 40px 0;
		& [data-button="media requests button"] {
			float: right;
			& .loader {
				margin: 0 5px;
			}
		}
	}
	& .asset_card {
		width: 320px;
		padding: 10px;
		margin: 20px;
		border: 1px solid #e7e7e7;
		& .actionButtons {
			text-align: right;
		}
		& .asset_card_thumb_wrapper {
			height: 300px;
		}
		& .asset_card_desc_wrapper {
			clear: both;
			& .card_metadata {
				border-top: 1px solid #e7e7e7;
				padding-top: 5px;
				margin-top: 5px;
				font-size: 0.9em;
				line-height: 150%;
			}
			& .approver_actions {
				background-color: $gac-sand;
				padding: 8px;
				margin: 8px 0 0 0;
				& .radio_button_approve {
					margin: 0 5px 0 0;
					color: $gac-utility-green;
				}
				& .radio_button_deny {
					margin: 0 0 0 5px;
					color: $gac-utility-red;
				}
			}
		}
		& [data-format="video"] {
			position: relative;
		}
	}
	& .preview {
		width: 100% !important;
		max-width: 800px;
		& .asset_card_thumb_wrapper {
			height: auto !important;
			& .pdf_viewer {
				width: 100vw;
				height: 100vh;
				@include respond(small) {
					width: 50vw;
					height: 66vh;
				}
			}
		}
	}
	& #cart {
		& [data-button="cart review"] {
			font-size: 0.9em;
			margin-top: 30px;
		}

		& .cart_wrapper {
			margin: 30px 0;
			background-color: rgba(85, 130, 12, 0.5);
			padding: 10px;
			border: 1px solid rgba(85, 130, 12, 1);
			position: relative;
			& [data-header="cart header"] {
				font-size: 100%;
				border-bottom: 1px solid rgba(85, 130, 12, 1);
				& [data-span="cart actions"] {
					float: right;
					cursor: pointer;
					& span[data-button] {
						padding: 2px 8px;
					}
				}
			}
			& [data-wrapper="cart items"] {
				display: -webkit-box;
				display: -ms-flexbox;
				display: flex;
				-webkit-box-orient: horizontal;
				-webkit-box-direction: normal;
				-ms-flex-direction: row;
				flex-direction: row;
				-ms-flex-wrap: wrap;
				flex-wrap: wrap;
				margin: 20px 0 0 0;
			}
			& #cart_submit_form_wrapper {
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;
				display: flex;
				flex-direction: column;
				justify-content: space-around;
				align-items: center;
				background-color: rgba(255, 255, 255, 0.9);
				z-index: 20;
				& form {
					width: 90%;
					padding: 10px;
				}
				& input {
					width: 100%;
					height: 40px;
					appearance: none;
					border: 1px solid $gac-grey6;
					margin: 0 0 10px 0;
					padding: 0 0 0 10px;
					font-size: 1em;
					color: rgba(0, 0, 0, 0.7);
				}
				& input::-webkit-input-placeholder {
					color: rgba(0, 0, 0, 0.3);
				}
				& input::-moz-placeholder {
					color: rgba(0, 0, 0, 0.3);
				}
				& input:-ms-input-placeholder {
					color: rgba(0, 0, 0, 0.3);
				}
				& input::-ms-input-placeholder {
					color: rgba(0, 0, 0, 0.3);
				}
				& input::placeholder {
					color: rgba(0, 0, 0, 0.3);
				}
				& [data-button] {
					margin: 10px auto;
				}
				& [data-section="cart form actions"] {
					float: right;
				}
				& input.error,
				div.error,
				textarea.error,
				select.error {
					border: 1px solid $gac-utility-red;
				}
			}
		}
	}
	& .asset_collection {
		margin: 0 0 30px 0;
		& .asset_collection_field {
			& .field_name {
				font-size: 1.2em;
			}
			& .field_values_wrapper {
				margin: 40px 0 0 0;
				display: -webkit-box;
				display: -ms-flexbox;
				display: flex;
				-webkit-box-orient: horizontal;
				-webkit-box-direction: normal;
				-ms-flex-direction: row;
				flex-direction: row;
				-ms-flex-wrap: wrap;
				flex-wrap: wrap;
				-webkit-box-pack: space-evenly;
				-ms-flex-pack: space-evenly;
				justify-content: space-evenly;
				-webkit-box-align: start;
				-ms-flex-align: start;
				align-items: flex-start;
				& .field_value_assets_wrapper {
					margin: 10px 0 0 0;
					& .asset_value_name {
						margin: 0 0 20px 0;
						cursor: pointer;
					}
					& .asset_thumbs_wrapper {
						position: relative;
						height: 350px;
						width: 350px;
						& .thumbnail_wrapper {
							position: absolute;
							width: 320px;
							height: 300px;
							margin: 0 auto;
							border: 1px solid $gac-white;
							& img {
								-webkit-transform: rotate(0deg);
								-ms-transform: rotate(0deg);
								transform: rotate(0deg);
								top: 0px;
								left: 0px;
								-o-object-fit: cover;
								object-fit: cover;
								height: 100%;
								width: 100%;
								box-shadow: 0px -1px 1px 0px;
							}
						}
					}
					& .email_label {
						font-weight: bold;
						&:lang(zh-hans),
						:lang(zh-hant) {
							font-family: $font-family-cn !important;
							font-weight: 400;
						}
					}
				}
			}
		}
	}
}
