.pubDate {
	font-weight: 400;
	&:lang(zh-hans),
	:lang(zh-hant) {
		font-family: $font-family-heading-cn !important;
	}
	text-transform: uppercase;
	font-size: 0.4em;
	@include respond(small) {
		font-size: 0.6em;
	}
}
