#dark_item {
	background-color: $gac-blue;
	padding: 100px 40px;
	color: $gac-white;
	@include respond(small) {
		padding: 120px;
	}
	& a {
		color: $gac-white;
	}
	& .text_wrapper {
		max-width: 600px;
		margin: 0 auto;
		& .headline {
			font-size: 1em;
			line-height: 120%;
			margin-bottom: 20px;
			@include respond(small) {
				font-size: 2em;
				line-height: 120%;
			}
		}
		& .subhead {
			font-size: 0.8em;
			line-height: 140%;
		}
		& .read_more_button {
			margin-top: 50px;
			padding: 5px 50px;
			text-transform: capitalize;
			font-size: 0.7em;
			background-color: $gac-white;
			color: $gac-blue;
			& a {
				color: $gac_blue;
			}
		}
	}
}
hr.dark_news {
	margin-top: 0 !important;
}
