// absolutely center an element
@mixin absCenter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

// offset an in-page anchor element
@mixin offsetAnchor($top) {
  display: block;
  position: relative;
  top: $top;
  visibility: hidden;
}

// min-widths for each defined display size
@mixin respond($breakpoint) {
  @if $breakpoint == small {
    @media only screen and (min-width: $small-min-width-px) { @content };
  }
  @if $breakpoint == medium {
    @media only screen and (min-width: $medium-min-width-px) { @content };
  }
  @if $breakpoint == large {
    @media only screen and (min-width: $large-min-width-px) { @content };
  }
  @if $breakpoint == huge {
    @media only screen and (min-width: $huge-min-width-px) { @content };
  }
  @if $breakpoint == massive {
    @media only screen and (min-width: $massive-min-width-px) { @content };
  }
  
}

// css custom properties + fallback
@mixin var($property, $variable) {
  #{$property}: map-get($vars, $variable);
  #{$property}: var(--#{$variable});
}

// simplify keyframe animations
// https://joshbroton.com/quick-fix-sass-mixins-for-css-keyframe-animations/
@mixin animation($animate...) {
  $max: length($animate);
  $animations: '';

  @for $i from 1 through $max {
    $animations: #{$animations + nth($animate, $i)};

    @if $i < $max {
        $animations: #{$animations + ", "};
    }
  }
  -webkit-animation: $animations;
  -moz-animation:    $animations;
  -o-animation:      $animations;
  animation:         $animations;
}

@mixin keyframes($animationName) {
  @-webkit-keyframes #{$animationName} {
    @content;
  }
  @-moz-keyframes #{$animationName} {
    @content;
  }
  @-o-keyframes #{$animationName} {
    @content;
  }
  @keyframes #{$animationName} {
    @content;
  }
}