#social_feed_container {
	width: 100%;
	overflow-x: auto;
	margin: 0 0 30px;
	@include respond(small) {
		margin: 0 0 50px;
	}

	& #curator-feed-gac-instagram-layout {
		max-width: 100%;
		padding: 0;
		& .crt-carousel-feed {
			padding-bottom: 50px;
			& .crt-carousel-pane {
				font-size: smaller;
				& .crt-post-fullname {
					padding-left: 10px;
				}
				& .crt-post-header {
					text-align: left;
				}
				& .crt-post-text {
					text-align: left;
					font-size: smaller;
				}
			}
		}
		& .crt-logo {
			font-size: 10px;
		}
	}
}
