// font families
$font-family: "Gulfstream Horizon", Arial, sans-serif;
$font-family-cn: "Gulfstream Horizon", "Noto Sans SC", Arial, sans-serif;
$font-family-heading: "Gulfstream Horizon Display", Arial, sans-serif;
$font-family-heading-cn: "Gulfstream Horizon Display", "Noto Sans SC", Arial, sans-serif;

// browsers typically assume a base font-size of 16px
$base-font-size: 16px;

// for simplified font size calculations, set medium displays to 10px
// each breakpoint above and below will be adjusted accordingly
$medium-font-size-px: 10;
$xs-font-size-px: $medium-font-size-px - 2;
$small-font-size-px: $medium-font-size-px - 1;
$large-font-size-px: $medium-font-size-px + 1;
$huge-font-size-px: $medium-font-size-px + 2;

// set the root em (rem) adjustment value for each display size
$xs-font-size-adj: ($xs-font-size-px * 1px) / $base-font-size * 100%;
$small-font-size-adj: ($small-font-size-px * 1px) / $base-font-size * 100%;
$medium-font-size-adj: ($medium-font-size-px * 1px) / $base-font-size * 100%;
$large-font-size-adj: ($large-font-size-px * 1px) / $base-font-size * 100%;
$huge-font-size-adj: ($huge-font-size-px * 1px) / $base-font-size * 100%;

// when in doubt, set an element's font-size to this
$default-font-size: 1.3rem;
