.page-heading {
	font-family: $font-family-heading !important;
	&:lang(zh-hans),
	:lang(zh-hant) {
		font-family: $font-family-heading-cn !important;
	}
	font-weight: 100 !important;
	font-size: 0.8em !important;
	text-align: center !important;
	margin: 30px 0 !important;
	@include respond(small) {
		font-size: 1.4em !important;
		margin: 100px 0 !important;
	}
}
