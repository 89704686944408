#history_wrapper {
	// & .uk-padding-large {
	//     padding: 25px;
	//     @include respond(small){
	//         padding: 90px;
	//     }
	// }
	// & .uk-margin-large {
	//     margin: 25px;
	//     @include respond(small){
	//         margin: 90px;
	//     }
	// }
	& .era_separator {
		padding: 0 25px 25px !important;
		@include respond(small) {
			padding: 0 70px 50px 70px !important;
		}
		& hr {
			color: $gac-black;
			border-top: 0.5px solid $gac-grey9;
			padding: 0;
			margin: 0;
		}
	}
	& .heading {
		font-size: 0.8em;
		@include respond(small) {
			font-size: 1.2em;
		}
	}
	& #hero_image {
		height: 35vh;
		width: 100vw;
		@include respond(small) {
			height: 600px;
		}
		& img {
			height: auto;
			width: 100%;
		}
	}
	& .caption {
		font-size: 0.4em;
		line-height: 120%;
		margin-top: 10px;
		@include respond(small) {
			font-size: 0.65em;
		}
	}
	& #history_accordion {
		@include respond(small) {
			margin-top: 90px !important;
		}
		& li.uk-open {
			& .era_title {
				@include respond(small) {
					font-size: 1.6em;
					&::before {
						background-image: url(data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2210%22%20height%3D%2210%22%20viewBox%3D%220%200%2010%2010%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Crect%20fill%3D%22%23666%22%20width%3D%2210%22%20height%3D%22.7%22%20x%3D%220%22%20y%3D%226%22%20%2F%3E%0A%3C%2Fsvg%3E);
						width: 0.75em;
						top: -4px;
					}
				}
			}
		}
		& .era_title {
			color: $gac-black;
			font-size: 0.7em;
			@include respond(small) {
				margin-bottom: 50px;
				font-size: 1.2em;
				&::before {
					height: 1em;
					width: 1em;
					background-size: cover;
					position: relative;
					top: 5px;
				}
			}
		}
		& .era_content {
			font-size: 0.65em;
			line-height: 170%;
			@include respond(small) {
				font-size: 0.95em;
				& .date {
					font-size: 0.8em;
					margin-top: 0px !important;
				}
			}
			& .event {
				& .subhead {
					font-size: 1.2em;
					padding-right: 25px !important;
					@include respond(small) {
						padding-left: 0 !important;
					}
				}
				& .event_content {
					padding-right: 25px !important;
					@include respond(small) {
						padding-left: 0 !important;
						padding-right: 0 !important;
					}
					& .event_title {
						// margin-bottom: 10px;
					}
					.event_info {
						margin-top: 10px;
					}
					& .event_title,
					.event_info {
						@include respond(small) {
							max-width: 1000px;
							padding-right: 50px;
						}
					}
				}
				& .event_image {
					position: relative;
					margin-right: 0 !important;
					margin-left: 0 !important;
					// margin-bottom: 40px !important;
					@include respond(small) {
						margin-top: 50px !important;
						margin-right: 70px !important;
						margin-left: 40px !important;
						&.flush {
							margin-right: 0px !important;
							margin-left: 0px !important;
						}
						&.right {
							margin-left: 0px !important;
							margin-right: 70px !important;
						}
						&.left {
							left: calc((-100vw * 1 / 3.001) + 10px);
						}
						&.flush.left {
							left: calc(-100vw * 1 / 3.001);
						}
						&.flush.right {
							margin-left: 0px;
							margin-right: 0px !important;
						}
					}
					@include respond(large) {
						&.flush.left {
							left: calc((-100vw * 1 / 3.001) - 20px);
						}
					}
					& .image_wrapper {
						width: 100%;
						height: 300px;
						@include respond(small) {
							height: 400px;
						}
						@include respond(medium) {
							height: 500px;
						}
						@include respond(large) {
							height: 600px;
						}
						@include respond(huge) {
							height: 800px;
						}
						& img {
							width: 100%;
							height: 100%;
						}
					}
					& .caption {
						font-size: 0.6em;
						@include respond(small) {
							font-size: 0.7em;
							&.left {
								padding: 0;
							}
						}
					}
				}
			}
		}
	}
}
