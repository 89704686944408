$gac-black: #1a1919;
$gac-gold: #ca8448;
$gac-peri: #7d98aa;
$gac-blue: #041c2c;
$gac-white: #ffffff;

$gac-slate: #898d8d;
$gac-sand: #f2f0e3;
$gac-orange: #c88242;
$gac-steel: #7a99ac;
$gac-teal: #7fa9ae;
$gac-brown: #5b3427;

$gac-utility-blue: #2663c9;
$gac-utility-green: #55820c;
$gac-utility-yellow: #ffb31a;
$gac-utility-orange: #f07f07;
$gac-utility-red: #b9252b;

$gac-grey1: #fafafa;
$gac-gray1: $gac-grey1;
$gac-grey2: #f2f3f4;
$gac-gray2: $gac-grey2;
$gac-grey3: #e6e8ea;
$gac-gray3: $gac-grey3;
$gac-grey4: #ccd1d4;
$gac-gray4: $gac-grey4;
$gac-grey5: #b4bbc0;
$gac-gray5: $gac-grey5;
$gac-grey6: #9aa4aa;
$gac-gray6: $gac-grey6;
$gac-grey7: #818d95;
$gac-gray7: $gac-grey7;
$gac-grey8: #687680;
$gac-gray8: $gac-grey8;
$gac-grey9: #4f606b;
$gac-gray9: $gac-grey9;
$gac-grey10: #364956;
$gac-gray10: $gac-grey10;
$gac-grey11: #1d3341;
$gac-gray11: $gac-grey11;
$gac-grey12: #050f17;
$gac-gray12: $gac-grey12;
