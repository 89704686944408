#orders_wrapper {
    max-width: 1600px;
    margin: 0 auto;
    & #orders_list_wrapper {
        .asset_details {
            font-size: 0.8em;
            line-height: 140%;
            @include respond(small) {
                font-size: 0.9em;
            }
            & .order_index {
                font-size: 2em;
            }
            & .detail_table {
                & thead {
                    border-bottom: 1px solid $gac-grey4;
                    & th {
                        font-size: 0.8em;
                        color: $gac-black;
                        text-transform: capitalize !important;
                        padding: 22px 5px 10px 0px !important;
                    }
                }
                & tbody {
                    & td {
                        padding: 22px 12px 22px 0px !important;
                    }
                }
            }
        }
        & .order_submit {
            max-width: 180px;
        }
        &:last-child {
            margin-bottom: 0 !important;
        }
        & .order_id {
            font-size: 0.8em;
            color: $gac-grey7;
        }
        & .status_label {
            &.processing {
                color: $gac-utility-orange;
            }
            &.processed {
                color: $gac-utility-green;
            }
            &.expired {
                color: $gac-utility-red;
            }
        }
        & .requested_assets_label {
            font-style: italic;
            font-size: 0.9em;
        }
        & .requestor_details {
            margin: 10px 0;
        }
        & .requested_assets {
            margin: 20px 0 0;
        }
        & .asset_group {
            margin-top: 10px;
            padding: 40px 0;
            & .asset_name {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
            & .asset_wrapper {
                & .categories {
                    text-transform: capitalize;
                }
                & .asset_thumb_wrapper {
                    width: 100%;
                    height: 180px;
                    margin: 10px 0;
                    @include respond(small) {
                        height: 250px;
                    }
                }
                & label {
                    font-size: 0.8em;
                }
                & .metadata {
                    font-size: 0.8em;
                    line-height: 150%;
                }
            }
        }
        & .processed,
        .processed textarea {
            color: $gac-grey5;
            & a {
                color: $gac-grey6 !important;
            }
            & img,
            .order_submit,
            .uk-radio:checked {
                opacity: 0.3 !important;
            }
        }
    }
    & #orders_form_wrapper {
        width: 100%;
        max-width: 1030px;
    }
    & input[type="text"] {
        border: 1px solid $gac-grey5;
        height: 50px;
    }
    & textarea {
        border: 1px solid $gac-grey5;
    }
}
