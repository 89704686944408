.separator {
    &.top {
        margin: 15px 0 0 0;
        @include respond(small) {
            margin: 25px 0 0 0;
        }
    }
    &.bottom {
        margin: 0 0 15px 0;
        @include respond(small) {
            margin: 0 0 25px 0;
        }
    }
    &.center {
        margin: 15px 0;
        @include respond(small) {
            margin: 25px 0;
        }
    }
    &.ruled {
        border-top: 1px solid $gac-grey6;
    }
}
