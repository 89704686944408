.callout {
  padding: 2rem;

  @include respond(medium) {
    padding: 5rem;
  }

  @include respond(huge) {
    padding: 11rem 21rem;
  }
  
  &--light {
    @include var(color, light-block-fg);
    @include var(background, light-block-bg);  
  }

  &--dark{
    @include var(color, dark-block-fg);
    @include var(background, dark-block-bg);  
  }

  &__container {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;    

    @include respond(small) {
      flex-direction: row;
      justify-content: space-around;
    }

    @include respond(medium) {
      justify-content: space-between;
    }
  }

  &__copyblock {
    padding-bottom: 2rem;

    @include respond(small) {
      width: 250px;
      padding-bottom: 0;
    }

    @include respond(medium) {
      width: 50%;
      max-width: 65rem;
      padding-right: 2rem;
    }

    @include respond(large) {
      max-width: unset;
    }

    &--second {
      @include respond(small) {
        order: 1;
        padding-left: 2rem;
        padding-right: unset;
      }
    }
  }

  &__copy {

    @include respond(large) {
      padding-left: 4rem;
      padding-right: 4rem;
    }

    @include respond(huge) {
      padding-left: 6rem;
      padding-right: 6rem;
    }

    & h1 {
      font-size: 2.4rem;
      line-height: 150%;
      margin-bottom: 1rem;
    }

    & h3 {
      font-size: 1.3rem;
      line-height: 200%;
      margin-bottom: 1rem;
    }

    & p {
      font-size: 1.8rem;

      @include respond(small) {
        font-size: 1.6rem;
      }
    }
  }

  &__imageblock {

    @include respond(small) {
      width: 250px;
    }

    @include respond(medium) {
      width: 50%;
    }
  }

  &__image {

    @include respond(small) {
      width: 100%;
      height: auto;
    }

    @include respond(medium) {
      width: 100%;
      height: auto;
    }

    & img {
      width: 100%;
      height: 100%;
    }
  }
}