#docModalBody,
#docEditorBody {
	& .uk-input {
		height: 50px;
	}
	& label {
		font-weight: bold;
	}
	& .doc_thumb {
		width: 200px;
		height: 300px;
		box-shadow: 5px 5px 20px -10px $gac-black;
		border: 1px solid $gac-grey2;
	}
}
