#userModal,
.standard_modal {
    & #userModalBody {
        position: relative;
    }
    & #confirm_edit_button {
        color: $gac-utility-green;
    }
    & #cancel_edit_button {
        color: $gac-utility-red;
    }
    & table {
        font-size: 0.8em;
        & th,
        td {
            line-height: 150%;
            & img {
                width: 200px !important;
                height: auto !important;
            }
        }
        & th {
            color: $gac-black;
            font-size: 1em;
        }
        & tr {
            & .editable {
                cursor: pointer;
            }
        }
    }
}
