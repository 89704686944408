#contact_wrapper {
	& #contact_content_wrapper {
		max-width: 1600px;
		& #contact_image_wrapper_mobile {
			width: 100%;
			height: auto;
			& img {
				width: 100%;
				height: auto;
			}
		}
		& #contact_image_wrapper_desktop {
			height: 43vw;
			width: 43vw;
			max-width: 688px;
			max-height: 688px;
		}
		& #contact_info_wrapper {
			& .contact_block {
				font-size: 0.7em;
				line-height: 160%;
				margin-bottom: 20px;
				@include respond(small) {
					font-size: 1em;
					margin-bottom: 30px;
					&:last-child {
						margin-bottom: 0;
					}
				}
				& .contact_overline {
					margin-bottom: 30px;
				}
				& .contact {
					margin-bottom: 20px;
					&:last-child {
						margin-bottom: 0;
					}
					@include respond(small) {
						margin-bottom: 30px;
					}
					& .contact-area {
						font-size: 0.9em;
						font-style: italic;
					}
				}
				& p {
					margin: 0;
				}
				& a {
					border-bottom: 1px solid $gac-blue;
					padding-bottom: 1px;
					&.no_line {
						border-bottom: 0;
					}
					&:hover {
						text-decoration: none;
					}
				}
				& .header {
					font-size: 1.1em;
				}
			}
		}
	}
}
