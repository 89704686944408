    .dimmer {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba(0,0,0,.25);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 40;
        & .loader {
            width: 50px;
            height: 50px;
        }
    }