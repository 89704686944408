#banner {
	background-color: $gac-blue;
	font-size: 0.6em;
	line-height: 120%;
	border-bottom: 1px solid $gac-white;
	color: $gac-white;
	position: relative;
	& a {
		color: $gac-white;
		text-decoration: underline;
	}
	& #banner_input_wrapper,
	#banner_text_wrapper {
		width: 90vw;
		margin: 0 auto;
		padding: 5px 0;
		@include respond(small) {
			width: 65vw;
		}
		& p {
			margin: 0;
		}
	}
	@include respond(small) {
		font-size: 0.8em;
	}
	& #banner_close_button {
		cursor: pointer;
		position: absolute;
		top: 5px;
		right: 5px;
		@include respond(small) {
			top: 20px;
			right: 40px;
		}
	}
	& form .select2-container {
		width: auto !important;
		color: $gac-white;
	}
	& .select2-selection__rendered {
		color: $gac-black !important;
	}
}
