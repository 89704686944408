$xs-min-width-px: 0;
$xs-min-width-em: 0;

$small-min-width-px: 769px;
$small-min-width-em: ($small-min-width-px / $base-font-size) * 1em; // 37.5em

$medium-min-width-px: 900px;
$medium-min-width-em: ($medium-min-width-px / $base-font-size) * 1em; // 56.25em;

$large-min-width-px: 1200px;
$large-min-width-em: ($large-min-width-px / $base-font-size) * 1em; // 75em;

$huge-min-width-px: 1800px;
$huge-min-width-em: ($huge-min-width-px / $base-font-size) * 1em; // 112.5em;

$massive-min-width-px: 2600px;
$massive-min-width-em: ($massive-min-width-px / $base-font-size) * 1em; ;