#footer {
	@include var(background, dark-block-bg);
	@include var(color, dark-block-fg);
	padding-top: 50px;
	padding-bottom: 50px;
	& #links-social-row {
		display: block;
		@include respond(small) {
			display: flex;
			margin-bottom: 60px;
		}
		& .links {
			& .link {
				font-size: 0.45em;
				line-height: 190%;
				@include respond(small) {
					font-size: 0.8em;
					&.uk-width-1-4 {
						width: 25% !important;
					}
					&.uk-width-2-3 {
						width: calc(100% * 2 / 3.001) !important;
					}
				}
				& #ot-sdk-btn.ot-sdk-show-settings {
					color: #ffffff !important;
					border: none !important;
					padding: 0 !important;
					font-size: inherit !important;
					line-height: inherit !important;
					&:hover {
						background-color: unset !important;
					}
				}
			}
		}
		& .footer_social {
			padding-bottom: 50px;
			margin-top: 50px;
			@include respond(small) {
				margin-top: inherit;
			}
			.uk-icon-button {
				background-color: transparent;
				border: 1px solid $gac-white;
				color: $gac-white;
				margin-left: 10px;
				width: 25px;
				height: 25px;
				margin-bottom: 2px;
				&:hover {
					color: $gac-grey4;
					border-color: $gac-grey4;
				}
				@include respond(small) {
					margin-left: 20px;
					width: 40px;
					height: 40px;
				}
				&:first-child {
					margin-left: 0;
				}
				& svg {
					width: auto;
					height: 50%;
					fill: currentColor;
				}
			}
		}
	}
	& #logo-copy-row {
		line-height: 70%;
		& #disclaimer {
			margin-top: 10px;
			@include respond(small) {
				font-size: 0.8em;
			}
		}

		& .gd_logo {
			margin-bottom: 20px !important;
			@include respond(small) {
				margin-bottom: 0 !important;
			}
			& svg {
				fill: $gac-white;
				width: 120px;
				height: auto;
				@include respond(small) {
					width: 230px;
				}
			}
		}

		& .rights {
			font-size: 0.45em;
			margin-top: 20px;
			@include respond(small) {
				font-size: 0.7em;
				margin-top: inherit;
			}
		}
	}
	@include respond(small) {
		padding: 90px !important;
	}
}
