*,
*::after,
*::before {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

:root {
	@each $property, $value in $vars {
		--#{$property}: #{$value};
	}
}

// @media (prefers-color-scheme: dark) {
//   :root {
//     @each $property, $value in $darkvars {
//       --#{$property}: #{$value};
//     }
//   }
// }

html {
	@include var(font-size, font-adjust-xs);
	@include respond(small) {
		@include var(font-size, font-adjust-sm);
	}
	@include respond(medium) {
		@include var(font-size, font-adjust-md);
	}
	@include respond(large) {
		@include var(font-size, font-adjust-lg);
	}
	@include respond(huge) {
		@include var(font-size, font-adjust-hg);
	}
	height: 100%;
}

body {
	margin: 0;
	padding: 0;
	@include var(background, color-secondary);
	@include var(color, color-primary);
	@include var(font-size, default-font-size);
	font-family: $font-family !important;
	line-height: 160%;
	min-height: 100%;
	&:lang(zh-hans),
	:lang(zh-hant) {
		font-family: $font-family-cn !important;
	}
}

h1 {
	font-family: $font-family-heading !important;
	&:lang(zh-hans),
	:lang(zh-hant) {
		font-family: $font-family-heading-cn !important;
		font-weight: 400;
	}
	text-transform: uppercase;
}
h2 {
	font-family: $font-family !important;
	font-weight: 100;
	&:lang(zh-hans),
	:lang(zh-hant) {
		font-family: $font-family-cn !important;
	}
}
a {
	@include var(color, color-tertiary);
}
