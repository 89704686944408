$vars: (
    font-adjust-xs: $xs-font-size-adj,
    font-adjust-sm: $small-font-size-adj,
    font-adjust-md: $medium-font-size-adj,
    font-adjust-lg: $large-font-size-adj,
    font-adjust-hg: $huge-font-size-adj,
    default-font-size: $default-font-size,
    color-primary: $gac-blue,
    color-secondary: $gac-white,
    color-tertiary: $gac-grey10,
    light-block-fg: $gac-grey10,
    light-block-bg: $gac-white,
    dark-block-fg: $gac-white,
    dark-block-bg: $gac-blue,
    error: $gac-utility-red,
    warning: $gac-utility-yellow,
    info: $gac-utility-blue,
    success: $gac-utility-green,
);

$darkvars: (
    color-primary: $gac-grey10,
    color-secondary: $gac-blue,
    color-tertiary: $gac-white,
    light-block-fg: $gac-sand,
    light-block-bg: $gac-slate,
    dark-block-fg: $gac-gold,
    dark-block-bg: $gac-blue,
);

// spacings
$page-margin: 30px;
$page-margin-large: 50px;