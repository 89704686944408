.select2-container--default .select2-selection--single .select2-selection__arrow b {
    display: none !important;
    
}

#filter_wrapper {
    & :focus {
        outline: 2px solid $gac-grey4;

    }
    & form {
        position: relative;
        & div:not(#submit_button):first-child {
            padding-left: 15px !important;
            @include respond(small){
                padding-left: 0px !important;
            }
        }
        @include respond(small){
            width: 100%;
            margin: 0 auto;
        }
        & #submit_button, #clear_form_button {
        margin: 0;
        padding: 0;
        @include respond(small){
            height: 50px !important;
            margin-top: 20px;
        }
        & button {
            width: 95%;
            height: 50px;
            font-size: .8em;
        }
    }
    }
    & .filter_label {
        font-size: .8em;
        margin: 0 0 5px 0;
        display: block;
    }
    & .filter_input {
        border: 1px solid $gac-grey8;
        padding: 5px;
        font-size: .8em;
        height: 50px;
        &:focus {
            outline: 2px solid $gac-grey4;
        }
    }
    & .select2-selection  {
        font-size: .8em;
        height: 50px !important;
        border-radius: 0 !important;
        border: 1px solid $gac-grey8 !important;
            & .select2-selection__arrow, .select2-selection__rendered {
                position: absolute;
                top: 50%;
                height: auto;
                transform: translateY(-50%);
                pointer-events: none;

            }
    }
    & .select2-results {
        font-size: .8em;
        & li {
            border-bottom: 1px solid $gac-grey5;
        }
        & li.select2-results__option--highlighted[aria-selected]{
            color: $gac-grey6 !important;
            background-color: $gac-white !important;
        }
    }
    

}

