.hero {
    min-height: 200px;
    height: 400px;
    width: 100vw;
    @include respond(small){
        height: 600px;
    }
    @include respond(huge){
        height: 900px;
    }
}

