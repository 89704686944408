.signed_in {
    font-size: .6em;
    color: $gac-grey8;
    & a {
        color: $gac-grey8;
    }
    @include respond(small) {
        
        position: absolute;
        top: 0px;
        right: 70px;
    }
    
}
.darkpage_toggle {
    margin-left: 4px;
}
.admin_welcome #admin_help_link {
    margin-left: 7px;
}
