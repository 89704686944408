form {
	width: 100%;
	input {
		font-size: 0.9em !important;
	}
	& #upload_progress {
		& .bar {
			background-color: $gac-blue;
			height: 20px;
			margin: 5px 0;
			width: 0px;
		}
	}
	& input,
	select,
	.select2-selection,
	button,
	textarea {
		&.error {
			background-color: rgba($gac-utility-orange, 0.2);
			color: $gac-utility-orange;
			border: 1px solid $gac-utility-orange;
		}
	}
	& .field {
		text-align: left;
		margin: 0 0 10px 0;
		& input,
		textarea,
		select,
		.select2-selection {
			height: 50px;
			border-radius: 0;
			padding: 10px;
			font-size: 0.9em;
			font-family: $font-family;
			&:lang(zh-hans),
			:lang(zh-hant) {
				font-family: $font-family-cn !important;
			}
		}

		& input[type="radio"],
		input[type="checkbox"] {
			height: auto !important;
			width: auto !important;
			padding: 6px !important;
		}
		& input[type="radio"] {
			border-radius: 50% !important;
		}
		&.error {
			color: $gac-utility-orange;
			& input,
			select,
			.select2-selection,
			textarea {
				background-color: rgba($gac-utility-orange, 0.2);
				border: 1px solid $gac-utility-orange;
			}
		}
	}
	& .select2-container {
		width: 100% !important;
		height: 50px;
		background-color: $gac-white;
		// max-width: 350px;
		& .select2-selection {
			height: 40px;
			border: 1px solid $gac-grey3;
			border-radius: 0 !important;
			background-color: transparent;
			&.select2-selection--multiple {
				& ul.select2-selection__rendered {
					top: 0;
					transform: none;
				}
			}
		}
		&.select2-container--default {
			border: 1px solid $gac-grey3;
		}
		& .select2-selection__rendered {
			position: relative;
			top: 50%;
			transform: translateY(-50%);
			padding: 0;
			margin: 0;
			& input.select2-search__field {
				border: none;
				margin: 0;
			}
			& .select2-selection__choice {
				margin-top: 1px;
				border-radius: 0;
				height: 30px;
				display: flex;
				flex-direction: row;
				justify-content: center;
				align-items: center;
			}
		}
	}
	& select {
		width: 100%;
		min-width: 120px;
		border-radius: 0;
	}
	& select::-ms-expand {
		display: none; /* Hide the default arrow in Internet Explorer 10 and Internet Explorer 11 */
	}
	& textarea {
		padding: 10px;
		height: auto;
		width: 100%;
		border-color: $gac-grey3;
	}
	& input::-webkit-input-placeholder {
		color: rgba(0, 0, 0, 0.3);
	}
	& input::-moz-placeholder {
		color: rgba(0, 0, 0, 0.3);
	}
	& input:-ms-input-placeholder {
		color: rgba(0, 0, 0, 0.3);
	}
	& input::-ms-input-placeholder {
		color: rgba(0, 0, 0, 0.3);
	}
	& input::placeholder {
		color: rgba(0, 0, 0, 0.3);
	}
	& textarea::placeholder {
		color: rgba(0, 0, 0, 0.3);
	}
	& label {
		font-family: $font-family;
		font-size: 0.7em !important;
		line-height: 140%;
		text-transform: none;
		display: block;
		width: 100%;
		margin-bottom: 5px;
		&:lang(zh-hans),
		:lang(zh-hant) {
			font-family: $font-family-cn !important;
			font-weight: 400;
		}
		&.error {
			color: $gac-utility-orange;
		}
	}
	& .field.wider {
		width: 300px;
	}
	.field.error {
		& label {
			color: 1px sold $gac-utility-orange;
		}
	}
	& .js-upload.drag_entered {
		box-shadow: 0 0 20px -10px $gac-blue;
	}
}
.disabled_button {
	opacity: 0.5;
	cursor: wait;
}
.uk-sortable-empty {
	min-height: 0 !important;
}
.all_releases_button {
	text-transform: none !important;
	padding: 0 30px !important;
	font-size: 0.7em !important;
	height: 60px;
	&:hover {
		color: $gac-grey5 !important;
	}
	@include respond(small) {
		font-size: 1em !important;
	}
}
