.mce-content-body {
	padding: 10px;
}
#editor_wrapper {
	margin: 0 auto;
	#bio_editor {
		margin: 30px 0 50px 0;
		padding: 10px;
		border-bottom: 4px solid #e7e7e7;
		& .underline {
			border-bottom: 1px solid $gac-grey8;
		}
		& .pointer {
			cursor: pointer !important;
		}
		& iframe {
			width: 100%;
			height: 100vh;
			border: none;
			overflow: auto;
			& .search-container-header-title {
				display: none;
			}
		}
		& #bio_form {
			width: 100%;
			& #upload_progress {
				& .bar {
					background-color: $gac-blue;
					height: 20px;
					margin: 5px 0;
					width: 0px;
				}
			}
			& .field[data-field="tags"] {
				cursor: pointer;
				max-width: 300px;
			}
			& .field {
				text-align: left;
				margin: 0 0 10px 0;
				& input,
				textarea,
				select,
				.select2-selection {
					height: auto;
					border-radius: 0;
					margin: 0 0 3px 0;
					padding: 10px;
					font-size: 0.9em;
					font-family: $font-family;
					&:lang(zh-hans),
					:lang(zh-hant) {
						font-family: $font-family-cn !important;
					}
				}
				& .select2-container {
					width: 100%;
					& .select2-selection {
						height: 40px;
						border: 1px solid $gac-grey3;
						border-radius: 0 !important;
					}
					&.select2-container--default {
						border: 1px solid $gac-grey3;
					}
					& .select2-selection__rendered {
						position: relative;
						top: 50%;
						transform: translateY(-50%);
						padding: 0;
						margin: 0;
						& input.select2-search__field {
							border: none;
							margin: 0;
						}
						& .select2-selection__choice {
							margin-top: 1px;
							border-radius: 0;
							height: 30px;
							display: flex;
							flex-direction: row;
							justify-content: center;
							align-items: center;
						}
					}
				}
				& select {
					width: 100%;
					min-width: 120px;
					border-radius: 0;
				}
				& select::-ms-expand {
					display: none; /* Hide the default arrow in Internet Explorer 10 and Internet Explorer 11 */
				}
				& textarea {
					padding: 10px;
					height: auto;
				}
				& input::-webkit-input-placeholder {
					color: rgba(0, 0, 0, 0.3);
				}
				& input::-moz-placeholder {
					color: rgba(0, 0, 0, 0.3);
				}
				& input:-ms-input-placeholder {
					color: rgba(0, 0, 0, 0.3);
				}
				& input::-ms-input-placeholder {
					color: rgba(0, 0, 0, 0.3);
				}
				& input::placeholder {
					color: rgba(0, 0, 0, 0.3);
				}
				& textarea::placeholder {
					color: rgba(0, 0, 0, 0.3);
				}
				& [data-button="image upload button"] {
					color: $gac-grey8;
					background-color: transparent;
				}
				& [data-section="cart form actions"] {
					float: right;
				}
				& label {
					font-weight: bold;
					font-size: 0.7em;
					text-transform: uppercase;
					display: block;
					width: 100%;
					&:lang(zh-hans),
					:lang(zh-hant) {
						font-family: $font-family-cn !important;
						font-weight: 400;
					}
				}
				& [data-button="add tag"] {
					float: right;
					margin: 0 0 0 5px;
					padding: 2px 10px;
				}
				input[name="createdReadable"] {
					min-width: 205px;
				}
			}
			& .field.wider {
				width: 300px;
			}
			.field.error {
				& label {
					color: 1px solid $gac-utility-red;
				}
			}
			& #image_manager {
				margin: 30px 0 0 0;
				& .image_card {
					max-width: 380px;
					margin-top: 40px;
					& .image_header {
						font-size: 0.8em;
					}
					& .thumbnail_wrapper {
						width: 310px;
						height: 388px;
						margin: 10px auto;
						// & img {
						//     width: 100%;
						//     height: 100%;
						// }
					}
					& [data-button="remove image"] {
						margin-left: 10px;
					}
					& .card_meta_wrapper {
						font-size: 0.7em;
					}
				}
				& .image_card.preview {
					width: 100%;
				}
			}
			& #image_drop_area.drag_entered {
				box-shadow: 0 0 20px -10px $gac-blue;
			}
			& #footer,
			#newsBody {
				padding: 10px;
				border: 1px solid $gac-grey3;
				cursor: pointer;
				& p {
					margin: 0 0 0.5em 0;
				}
				& .footer-header {
					text-transform: uppercase;
				}
			}
			& #newsBody {
				min-height: 70px;
			}
			& .disabled_button {
				opacity: 0.5;
				cursor: wait;
			}
		}
	}
}
