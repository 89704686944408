.main__news,
.main__search,
.main__kits {
	& .metadata_wrapper {
		height: auto;
		max-width: 350px;
		font-size: 0.75em;
		padding: 20px;
		margin: 0;
		text-transform: initial;
		text-align: right;
		background-color: $gac-grey2;
		@include respond(small) {
			margin: 0 0 0 40px;
		}
		& .metadata_row {
			margin: 0 0 10px 0;
			& .metadata_label {
				margin-left: 10px;
				font-weight: 400;
				&:lang(zh-hans),
				:lang(zh-hant) {
					font-family: $font-family-cn !important;
				}
			}
			& .pubbed_wrapper,
			.tags_wrapper {
				& .open {
					border: 1px solid $gac-grey8;
				}
			}
			.tags_wrapper {
				.uk-icon {
					color: $gac-grey6;
				}
			}
			& [data-toggle] {
				position: relative;
				& .toggle-spinner {
					top: 10px;
					left: 10px;
					position: absolute;
					@include respond(small) {
						top: 5px;
					}
				}
				& .toggle-icon.pending {
					opacity: 0.25;
				}
			}
			& [data-lang] {
				margin: 0 5px;
				font-size: 1em;
				cursor: pointer;
				display: inline-block;
				width: 25px;
				@include respond(small) {
					margin: 0 5px;
					font-size: inherit;
				}
			}
			& [data-state="true"] {
				color: $gac-utility-green;
			}
			& [data-state="false"] {
				color: $gac-utility-red;
			}
			@include respond(small) {
				margin: 0;
				line-height: 175%;
			}
		}
		& div.metadata_row:last-child {
			margin: 0;
		}

		& .metaMsgTop,
		.metaMsgBottom {
			margin: 0 0 10px 10px;
			text-align: left;
			background-color: transparent;
			font-size: 1.2em;
		}
	}
}
