.ordered_items {
    @include respond(huge) {
        padding-left: 150px !important;
        padding-right: 150px !important;
    }
    .ordered_item {
        & .ordered_image_wrapper {
            height: 300px;
            width: 100%;
            @include respond(small) {
                height: 450px;
            }
            @include respond(huge) {
                height: 650px;
            }
        }
        & .headline {
            & a {
                text-decoration: none;
                &:hover {
                    color: $gac-grey9;
                }
            }
        }
        & .summary {
            font-size: 0.8em;
            line-height: 140%;
            @include respond(small) {
                font-size: 1.15rem;
                line-height: 160%;
            }
        }
        & .read_more {
            @include respond(small) {
                font-size: 1.1rem;
            }
            & a {
                text-decoration: underline;
            }
        }
    }
}
