.featured__block {
	background-color: $gac-grey7;
	& .image_wrapper {
		&.screen {
			background-color: $gac-grey5;
			@include respond(small) {
				min-height: 550px;
				padding: 25px !important;
			}
			@include respond(medium) {
				padding: 70px !important;
			}
			@include respond(large) {
				padding: 120px !important;
			}
			@include respond(massive) {
				padding: 180px !important;
			}
		}
		@include respond(small) {
			max-width: 50%;
		}
		& img {
			max-height: 50vh;
			@include respond(small) {
				max-height: 60vh;
			}
		}
	}
	& .copy_wrapper {
		& .newsroom_header {
			@include respond(small) {
				padding-top: 25px;
			}
			@include respond(medium) {
				padding-top: 70px;
			}
			@include respond(large) {
				padding-top: 120px;
			}
			@include respond(massive) {
				padding-top: 180px;
			}
		}
		& .subhead_date {
			@include respond(small) {
				padding: 0 25px 25px;
			}
			@include respond(medium) {
				padding: 0 40px 70px;
			}
			@include respond(large) {
				padding-bottom: 120px;
			}
			@include respond(massive) {
				padding: 0 70px 180px;
			}
			& .subhead {
				font-size: 0.8em;
				line-height: 130%;
				margin: 20px 5px 10px !important;
				@include respond(small) {
					line-height: 110% !important;
				}
				@include respond(large) {
					font-size: 1em !important;
					line-height: 120% !important;
					margin: 20px 5px 30px !important;
				}
				@include respond(massive) {
					font-size: 1.2em !important;
				}
			}
			& .pubDate {
				line-height: 100%;
			}
		}
		& .headline {
			font-size: 0.9em;
			line-height: 160%;
			text-transform: uppercase;
			font-family: $font-family-heading;
			text-transform: uppercase;
			margin: 0 10px !important;
			font-family: $font-family-heading;
			&:lang(zh-hans),
			:lang(zh-hant) {
				font-family: $font-family-heading-cn !important;
				font-weight: 400;
			}
			& a {
				text-decoration: none;
				&:hover {
					color: $gac-grey9;
				}
			}
			&:lang(zh-hans),
			:lang(zh-hant) {
				font-family: $font-family-cn !important;
			}
			@include respond(small) {
				font-size: 0.7em !important;
				line-height: 110% !important;
				margin: 0 30px !important;
			}
			@include respond(medium) {
				font-size: 1em !important;
				line-height: 130% !important;
			}
			@include respond(large) {
				font-size: 1.2em !important;
				margin: 0 60px 50px !important;
				line-height: 140% !important;
			}
			@include respond(massive) {
				font-size: 2em !important;
				line-height: 160% !important;
				margin: 0 180px !important;
			}
		}
	}
}
