[uk-modal] {
    .uk-modal-header {
        padding: 0 5px 5px;
        margin-bottom: 0px;
        @include respond(small) {
            padding: 0 10px 10px;
            margin-bottom: 0px;
        }
        & .preview-meta {
            font-size: 0.5em;
            line-height: 150%;
            padding: 0;
            margin: 0;
        }
        .uk-modal-title {
            font-size: 0.8em;
            margin-top: 0;
            padding: 0;
            & .title_text {
                text-overflow: ellipsis;
                overflow: hidden;
                padding-right: 10%;
            }
            @include respond(small) {
                font-size: 1em;
            }
            & #preview-gallery-prev {
                cursor: pointer;
                margin-right: 20px;
                margin-left: 0px;
                @include respond(small) {
                    margin-left: 40px;
                }
            }
            & #preview-gallery-next {
                cursor: pointer;
            }
        }
    }
    .uk-modal-body {
        padding: 5px !important;
        @include respond(small) {
            padding: 20px 10px 10px !important;
        }
        & .documentPreview {
            height: 80vh;
        }
        & iframe {
            width: 100%;
            height: 100%;
        }
        & img {
            width: 100%;
            height: intrinsic;
        }
    }
}
