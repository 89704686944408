#body {
	display: flex;
	flex-direction: column;
	font-weight: 100;
	& .headline:lang(ru) {
		word-break: break-word;
	}
	& #main {
		flex-grow: 1;
	}
	& .bold {
		font-weight: normal;
		&:lang(zh-hans),
		:lang(zh-hant) {
			font-family: $font-family-cn !important;
		}
	}
	& .italic {
		font-style: italic;
	}
	& .hidden {
		display: none;
	}
	& .caret {
		background-size: 10px 10px !important;
		background-position: right 10px bottom 5px !important;
		line-height: 160% !important;
		padding-right: 40px !important;
	}
	& .arrow {
		display: inline-block;
		position: relative;
		top: 4px;
	}
	.breakable {
		overflow-wrap: break-word;
	}
	.capitalize {
		text-transform: capitalize;
	}
	.heading {
		font-family: $font-family-heading;
		&:lang(zh-hans),
		:lang(zh-hant) {
			font-family: $font-family-heading-cn !important;
		}
	}
	h1,
	.heading {
		text-transform: uppercase;
	}
	a.underline {
		text-decoration: underline !important;
	}
	.underline:not(a) {
		border-bottom: 1px solid $gac-black;
		padding-bottom: 2px;
	}
	button,
	.pointer {
		cursor: pointer;
	}
	.text-green {
		color: $gac-utility-green;
	}
	.text-red {
		color: $gac-utility-red;
	}
}
