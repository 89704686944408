#cr_wrapper {
	& button {
		font-size: 0.8em !important;
	}
	& #cr_factsheet_buttons {
		margin-bottom: 80px !important;
		margin-top: 60px !important;
	}
	& #cr_list_wrapper,
	#cr_editor_wrapper,
	#cr_admin_wrapper,
	#community_links_content_wrapper {
		max-width: 1600px;
		& .asset_wrapper {
			& .edit_tools_wrapper {
				background-color: $gac-sand;
			}
			& .media_image_wrapper {
				height: 300px;
			}
			& .metadata_container {
				& .pub_date_wrapper {
					cursor: pointer;
					& .pub_date {
						font-size: 0.8em;
					}
				}
				& .headline {
					font-size: 0.8em;
					line-height: 140%;
					padding-right: 15px;
				}
				& .item {
					font-size: 0.8em;
					line-height: 180%;
				}
			}
		}
		.target {
			padding: 100px !important;
			& .cr_blurb_wrapper {
				& .cr_blurb_image_wrapper {
					height: 400px;
					& img {
						max-height: 100%;
					}
				}
				& .cr_blurb_copy_wrapper {
					max-height: 400px;
					overflow: auto;
					padding-right: 15px;
					& .date {
						font-size: 0.6em;
					}
					& .headline {
						font-size: 1.2em;
					}
					& .item {
						font-size: 0.9em;
						& p {
							margin: 0 0 10px 0 !important;
							&:last-child {
								margin: 0 !important;
							}
						}
						& a {
							text-decoration: underline !important;
						}
					}
				}
			}
		}
		& .slideshow {
			& ul.uk-slideshow-items {
				li {
					&.image {
						height: 100%;
						// @include respond(small) {
						// 	height: 100%;
						// }
						& img {
							width: 100%;
							height: auto;
						}
					}
				}
			}
			& .uk-dotnav {
				// & li {
				// 	& a {
				// 		border-color: $gac-white !important;
				// 	}
				// 	&.uk-active {
				// 		& a {
				// 			background-color: $gac-white !important;
				// 		}
				// 	}
				// }
			}
		}
	}

	& #admin_tools {
		max-width: 1600px;
		& #cr_editor_wrapper {
			& #blurb_editor {
				& [data-wrapper="form wrapper"] {
					& form {
						& #image_upload_wrapper {
							& #image_manager {
								& .image_card {
									width: 100%;
									max-width: 400px;
									margin: 20px 0 0 0;
									& .image_header {
									}
									& .thumbnail_wrapper {
										width: 100%;
										max-width: 500px;
										& img {
											width: 100%;
											height: auto;
										}
									}
									& .card_meta_wrapper {
										font-size: 0.6em;
										line-height: 140%;
									}
								}
							}
						}
						& #form_errors {
							background-color: rgba($gac-utility-red, 0.25);
							border: 1px solid $gac-utility-red;
						}
					}
				}
			}
		}
		& #reorder_wrapper {
			& #reorder_list {
				& .reorder_item_wrapper {
					& .reorder_item_image_wrapper {
						height: 120px;
					}
					& .reorder_item_copy {
						font-size: 0.5em;
						line-height: 150%;
					}
				}
			}
		}
	}
}
