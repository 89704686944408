#gin_editor_wrapper,
#gic_editor_wrapper {
    margin: 0 auto;
    & #gin_editor,
    #gic_editor {
        & select,
        .select2-selection {
            height: auto;
            border-radius: 0;
            margin: 0 0 3px 0;
            padding: 10px;
            font-size: 0.9em;
            font-family: $font-family;
            &:lang(zh-hans),
            :lang(zh-hant) {
                font-family: $font-family-cn !important;
            }
        }
        & .select2-container {
            width: 100%;
            & .select2-selection {
                height: 40px;
                border: 1px solid $gac-grey3;
                border-radius: 0 !important;
            }
            &.select2-container--default {
                border: 1px solid $gac-grey3;
            }
            & .select2-selection__rendered {
                position: relative;
                top: 50%;
                transform: translateY(-50%);
                padding: 0;
                margin: 0;
                & input.select2-search__field {
                    border: none;
                    margin: 0;
                }
                & .select2-selection__choice {
                    margin-top: 1px;
                    border-radius: 0;
                    height: 30px;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                }
            }
        }
        & select {
            width: 100%;
            min-width: 120px;
            border-radius: 0;
        }
        & select::-ms-expand {
            display: none; /* Hide the default arrow in Internet Explorer 10 and Internet Explorer 11 */
        }
        & .error {
            border-color: $gac-utility-orange;
        }
    }
}
#saveGinOrderButton,
#saveGicOrderButton {
    margin-bottom: 10px;
}
