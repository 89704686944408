.text-grey1 {
    color: $gac-grey1 !important;
}
.text-grey2 {
    color: $gac-grey2 !important;
}
.text-grey3 {
    color: $gac-grey3 !important;
}
.text-grey4 {
    color: $gac-grey4 !important;
}
.text-grey5 {
    color: $gac-grey5 !important;
}
.text-grey6 {
    color: $gac-grey6 !important;
}
.text-grey7 {
    color: $gac-grey7 !important;
}
.text-grey8 {
    color: $gac-grey8 !important;
}
.text-grey9 {
    color: $gac-grey9 !important;
}
.text-grey10 {
    color: $gac-grey10 !important;
}
.text-grey11 {
    color: $gac-grey11 !important;
}
.text-grey12 {
    color: $gac-grey12 !important;
}
.text-white {
    color: $gac-white !important;
}
.text-blue {
    color: $gac-blue !important;
}
.text-steel {
    color: $gac-steel !important;
}
.text-teal {
    color: $gac-teal !important;
}
.text-orange {
    color: $gac-orange !important;
}
.text-slate {
    color: $gac-slate !important;
}
.text-sand {
    color: $gac-sand !important;
}
.text-brown {
    color: $gac-brown !important;
}
.text-black {
    color: $gac-black !important;
}
.text-gold {
    color: $gac-gold !important;
}
.text-peri {
    color: $gac-peri !important;
}
.text-utility-blue {
    color: $gac-utility-blue !important;
}
.text-utility-green {
    color: $gac-utility-green !important;
}
.text-utility-yellow {
    color: $gac-utility-yellow !important;
}
.text-utility-orange {
    color: $gac-utility-orange !important;
}
.text-utility-red {
    color: $gac-utility-red !important;
}
.text-utility-blue {
    color: $gac-utility-blue !important;
}
