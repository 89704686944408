#cart_counter {
	& .header_toggle {
		cursor: pointer;
	}
	& #cart_drop {
		width: 350px;
		max-width: 90vw;
		& .cart_counter_drop {
			max-height: 70vh;
			overflow: auto;
			padding: 25px;
			border: 1px solid $gac-black;
			background-color: $gac-white;
		}
		& #cart_list_wrapper {
			& .cart_info_row {
				margin-bottom: 10px;
				&:last-child {
					margin-bottom: 0;
				}
				& .asset_info_wrapper {
					& .cart_thumb {
						width: 80px;
						height: 50px;
					}
					& .cart_name {
						font-size: 0.6em;
						overflow: hidden;
						text-overflow: ellipsis;
						max-width: 180px;
						text-align: left;
						white-space: nowrap;
						margin-left: 10px;
					}
				}
			}
		}
	}
}
#assetModal {
	& .uk-modal-body {
		padding: 0 0 25px !important;
		@include respond(small) {
			padding: 0 0 70px !important;
		}
	}
	& .order_header {
		font-size: 1.1em;
		margin-bottom: 20px;
		@include respond(small) {
			font-size: 1.4em;
			margin-bottom: 40px;
		}
	}
	& #order_detail_table {
		border-collapse: collapse;
		table-layout: fixed;
		text-align: left;
		width: 100%;
		font-size: 0.8em;
		& thead {
			& th {
				line-height: 140%;
				font-weight: 400 !important;
				&:first-child {
					width: 90%;
				}
				&:nth-child(2) {
					width: 0%;
					@include respond(small) {
						width: 15%;
						visibility: visible !important;
					}
				}
				&:last-child {
					width: 7%;
					@include respond(small) {
						width: 15%;
					}
					text-align: right;
				}
				@include respond(small) {
					padding-bottom: 10px;
					border-bottom: 1px solid $gac-grey9;
				}
			}
		}
		& tbody {
			& .content_wrapper {
				margin-top: 15px;
				&:first-child {
					@include respond(small) {
						margin-top: 20px;
					}
				}
				& .order_thumb_wrapper {
					width: 100px;
					height: 70px;
					@include respond(small) {
						width: 180px;
						height: 120px;
					}
				}
				& .order_name {
					font-size: 0.8em;
					max-width: 150px;
					word-break: break-word;
					margin-left: 20px;
					line-height: 120%;
					@include respond(small) {
						font-size: 1em;
						max-width: 250px;
					}
				}
				&.remove_button_wrapper {
					@include respond(small) {
						padding-right: 25px;
					}
				}
			}
		}
	}
	& #order_form {
		margin-top: 45px;
		& .field {
			margin-top: 20px;
		}
		& .uk-flex-wrap {
			flex-wrap: wrap;
			@include respond(small) {
				flex-wrap: nowrap;
			}
		}
		label {
			line-height: 100%;
			& .helper_text {
				font-size: 0.6em;
			}
			@include respond(small) {
				font-size: 0.8em !important;
			}
		}
		& .name-input-wrapper {
			@include respond(small) {
				&:first-child {
					margin-right: 3px;
				}
				&:last-child {
					margin-left: 3px;
				}
			}
		}
		& #order_disclaimer {
			font-size: 0.6em;
			line-height: 140%;
			& p {
				margin-top: 20px;
				&:first-child {
					margin-top: 0;
				}
			}
		}
		& #required_notice {
			font-size: 0.6em;
			line-height: 140%;
		}
		& #order_button {
			font-size: 0.8em;
			text-transform: none;
		}
	}
}
