.loader {
    border: 5px solid $gac-white; /* Light portion */
    border-top: 5px solid $gac-grey8; /* Dark portion */
    border-radius: 50%;
    width: 25px;
    height: 25px;
    animation: spin 2s linear infinite;
    display: inline-block;
    vertical-align: middle;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
