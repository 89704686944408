// https://flickity.metafizzy.co/options.html
.carousel {
  background: $gac-white;
  width: 100%;
  & .carousel-cell {
        width: 100%;
        height: auto;
        margin: 0;
        background: $gac-white;
        border-radius: 0;
        & .slider__image {
            height: 500px;
            width: 100%;
        
            & img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                transition: opacity .4s;
                    opacity: 0;
            }
            & img.flickity-lazyloaded, img.flickity-lazyerror {
                opacity: 1;
            }
            @include respond(small) {
                height: 50rem;
            }
        }
    }
}

#article_wrapper .carousel {
    margin: 0 0 40px 0;
}
#feature_wrapper {
    padding: 10px;
    & .carousel  {
        margin: 0 0 40px 0;
    }
}
