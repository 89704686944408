#citizenship_report {
    & #citizenship_img_wrapper {
        width: 100%;
    }
    & #citizenship_copy_wrapper {
        margin: 45px 0 0 0; 
        @include respond(small){
            padding: 0 100px !important;
        }
        & #citizenship_header {
            line-height: 130%;
            margin-bottom: 15px;
            width: 100%;
            font-size: .8em;
            @include respond(small){
                font-size: 1.2em;
                width: 250px;
            }
        }
        & #citizenship_copy {
            font-size: .7em;
            line-height: 170%;
            @include respond(small){
                font-size: 1em;
            }
        }
        & #citizenship_button {
            width: 160px;
            height: 45px;
            text-transform: none;
            @include respond(small){
                font-size: .9em;
                width: 270px;
                height: 70px
            }
        }
    }
}