#about_wrapper {
	& #about_header_separator {
		padding: 0 25px;
		& hr {
			border-top: 1px solid $gac-grey4;
			padding: 0;
			margin: 0;
		}
	}
	& #saveOrderButton {
		margin: 25px auto;
		display: block;
		width: 150px;
	}
	& #bio_list_wrapper {
		@include respond(small) {
			max-width: 1600px;
		}
		& .bio_wrapper {
			& .details_wrapper {
				@include respond(small) {
					padding: 0 5px;
				}
				& .name {
					color: $gac-black;
					font-weight: 100;
					width: 90%;
					@include respond(small) {
						font-size: 1.4em;
					}
				}
				& .expand_bio {
					@include respond(small) {
						padding-top: 10px;
					}
				}
				& .title {
					@include respond(small) {
						font-size: 0.8em;
						width: 75%;
					}
				}
			}
			&:first-child {
				width: 100%;
				flex-direction: row;
				& > * {
					width: 48%;
				}
				& .details_wrapper {
					width: 46%;
					height: 100%;
					justify-content: center;
					margin: 0 0 0 15px !important;
					& .expand_bio_wrapper {
						margin: 0;
					}
				}
				@include respond(small) {
					width: 25%;
					flex-direction: column;
					& > * {
						width: 100%;
					}
					& .details_wrapper {
						width: 100%;
						height: auto;
						justify-content: start;
						margin: 25px 0 0 0 !important;
					}
				}
			}
			&:nth-child(2) {
				margin-top: 40px;
				@include respond(small) {
					margin-top: 0px;
				}
			}
			& .image_wrapper {
				width: 175px;
				height: 219px;
				@include respond(small) {
					width: 310px;
					height: 363px;
				}
				& img {
					object-fit: cover;
					width: 100%;
					height: 100%;
				}
			}
			& .title {
				margin-top: 5px !important;
			}
		}
	}
	& .bioText {
		background-color: $gac-grey2;
		& a {
			border-bottom: 1px solid $gac-black;
			text-decoration: none !important;
		}
		& .bio_close_button {
			top: 20px;
			right: 20px;
			@include respond(small) {
				top: 100px;
				right: 100px;
			}
		}
		& .expanded_name {
			font-size: 1.1em;
			line-height: 150%;
			@include respond(small) {
				font-size: 1.8em;
			}
		}
		& .expanded_title {
			font-size: 0.6em;
			line-height: 120%;
			@include respond(small) {
				font-size: 1em;
			}
		}
		& .download_wrapper {
			line-height: 120%;
			@include respond(small) {
				margin-bottom: 40px !important;
				& a,
				.pointer {
					font-size: 0.8em;
				}
			}
		}
		& .expanded_bio {
			font-size: 0.75em;
			line-height: 170%;
			& img.bio_thumb {
				display: none;
			}
			@include respond(small) {
				font-size: 1.1em;
			}
		}
		@include respond(small) {
			padding: 100px 150px;
		}
	}
}
