#aircraft_wrapper {
	& .hero {
		height: auto;
		& img {
			width: 100%;
		}
	}
	& #aircraft_list_wrapper {
		padding: 40px 25px 25px;
		@include respond(large) {
			padding: 70px;
		}
		@include respond(huge) {
			padding: 150px;
		}
		& .aircraft_block {
			margin-bottom: 40px;
			padding: 0 40px 70px;
			@include respond(small) {
				border-bottom: 1px solid black;
				margin-bottom: 70px;
				&:last-child {
					border: 0;
					padding: 0;
					margin-bottom: 0;
				}
			}
			@include respond(large) {
				padding: 0 40px 80px;
				margin-bottom: 70px;
				&:last-child {
					border: 0;
					padding: 0 40px;
					margin-bottom: 0px;
				}
			}
			@include respond(huge) {
				padding: 0 40px 150px;
				margin-bottom: 150px;
				&:last-child {
					border: 0;
					padding: 0 40px;
					margin-bottom: 0px;
				}
			}
			& .image {
				max-height: 250px;
				overflow: hidden;
				@include respond(small) {
					max-height: 600px;
				}
			}
			& .copy_wrapper {
				font-size: 1em;
				@include respond(small) {
					padding-left: 20px !important;
				}
				@include respond(medium) {
					padding-left: 90px !important;
				}
				& .product_link {
					margin-top: 10px;
					font-size: 0.6em;
					@include respond(medium) {
						margin-top: 0;
						font-size: 0.7em;
					}
				}
				& .product_header {
					font-size: 0.8em;
					line-height: 150%;
					@include respond(small) {
						margin-top: 10px;
					}
					@include respond(medium) {
						font-size: 1em;
					}
					@include respond(large) {
						font-size: 1.2em;
					}
				}
				& .copy {
					margin-top: 20px;
					font-size: 0.8em;
					line-height: 160%;
					@include respond(small) {
						margin-top: 15px;
					}
					@include respond(large) {
						font-size: 1em;
						margin-top: 15px;
					}
				}
				& .read_more_link {
					font-size: 0.8em;
					margin-top: 15px;
					@include respond(medium) {
						font-size: 1em;
					}
				}
			}
		}
	}
}
