#news_wrapper {
	& #news_fail {
		text-align: center;
		font-size: 1.5em;
		color: $gac-grey6;
	}
	& .release_wrapper {
		& #related_belt {
			& .card_wrapper {
				height: 100%;
				@include respond(small) {
					height: auto;
				}
			}
		}
		& .headline_wrapper,
		.body_wrapper,
		.footer_wrapper,
		.share_wrapper {
			padding: 0 20px;
			@include respond(small) {
				padding: 0 70px;
				max-width: 1000px;
			}
		}
		& .headline {
			@include respond(small) {
				font-size: 1.7em;
			}
		}
		& .subhead {
			font-size: 0.9em;
			line-height: 140%;
			@include respond(small) {
				font-size: 1em;
			}
		}
		& .single_image_wrapper {
			// width: 100%;
			// height: 30vh;
			// @include respond(small) {
			//     width: 100%;
			//     height: 55vh;
			// }
			// @include respond(huge) {
			//     width: 100%;
			//     height: 70vh;
			// }
		}
		& .image_wrapper,
		.related_slider {
			& .uk-slidenav {
				color: $gac-blue;
				&:focus {
					border: 1px solid $gac-grey5 !important;
				}
			}
			& .image_slide {
				// height: 478px;

				// @include respond(small) {
				//     height: 840px;
				// }
			}
			& li.related_slide {
				width: 70vw;
				margin-right: 10px;
			}
			& .download_link {
				@include respond(small) {
					margin: 0 20px 0 0 !important;
				}
			}
		}
		& .related_thumb_wrapper {
			width: 70vw;
			height: 30vh;
			@include respond(small) {
				width: 100%;
				height: 200px;
			}
			@include respond(huge) {
				width: 100%;
				height: 300px;
			}
			@include respond(massive) {
				width: 100%;
				height: 500px;
			}
		}

		& .download_link {
			font-size: 0.5em;
			@include respond(small) {
				font-size: 0.7em;
				text-align: right;
				margin: 20px 20px 0 0 !important;
			}
			& a {
				color: $gac-black; // override uk-light for gallery nav
			}
		}
		& .body_wrapper {
			font-size: 0.7em;
			line-height: 1.7;
			@include respond(small) {
				font-size: 1.1em;
			}
			& p.caption {
				font-size: 12px;
				font-style: italic;
				line-height: 1.5;
				color: inherit;
			}
			& .dateline {
				& .city {
					text-transform: uppercase;
				}
			}
		}
		& .footer_wrapper {
			font-size: 0.65rem;
			line-height: 200%;
			@include respond(small) {
				font-size: 0.8em !important;
			}
		}
		& .related_wrapper {
			position: relative;
			& .heading {
				font-size: 0.6em;
				line-height: 170%;
				@include respond(small) {
					font-size: 1.2em;
				}
			}
			& .related_headline {
				font-size: 0.7em;
				@include respond(small) {
					font-size: 0.8em;
				}
			}
			& .related_link {
				font-size: 0.7em;
				@include respond(small) {
					font-size: 0.8em;
				}
			}
		}
	}
}
