#pagination_wrapper {
	padding: 60px 0;
	& li {
		font-size: 0.9em;
		color: $gac-black;
		padding-left: 15px;
		@include respond(small) {
			padding-left: 20px;
		}
		&:first-child {
			padding-left: 0 !important;
		}
		& a {
			color: $gac-black;
			&:hover {
				color: $gac-grey8;
			}
			& .page {
				&.active {
					border: 1px solid;
					border-radius: 50%;
					height: 40px;
					width: 40px;
					padding: 0;
					margin: 0;
				}
			}
			& .dimmer {
				background-color: transparent;
				margin-left: 10px;
			}
			&.disabled {
				pointer-events: none;
				color: $gac-grey5;
			}
		}
	}
}
