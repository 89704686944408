#social_media_belt {
	position: relative;
	& .social_label {
		line-height: 200%;
		margin-bottom: 25px !important;
		@include respond(small) {
			margin-bottom: inherit !important;
		}
	}
	& .social_icon_wrapper {
		margin-bottom: 40px !important;
		@include respond(small) {
			margin-bottom: inherit !important;
		}
		& .uk-icon-button {
			border: 1px solid $gac-grey8;
			width: 30px;
			height: 30px;
			&:hover {
				background-color: $gac-white;
				border: 1px solid $gac-blue;
				& svg {
					fill: $gac-blue;
				}
			}
			@include respond(small) {
				width: 45px;
				height: 45px;
			}
			& svg {
				width: 15px;
				height: 15px;
				fill: currentColor;
				@include respond(small) {
					width: 20px;
					height: 20px;
				}
			}
		}
	}
}

.share_wrapper {
	& .uk-icon-button {
		border: 1px solid $gac-grey8;
		width: 30px;
		height: 30px;
		@include respond(small) {
			width: 45px;
			height: 45px;
		}
	}
	& .label {
		font-size: 0.5em;
		@include respond(small) {
			font-size: 0.7em !important;
		}
	}
}
