#kits_wrapper {
	width: 100%;
	// margin: 0 0 10px 0;
	& #kits_filter {
		max-width: 1200px;
		& form {
			& .select2-container--default .select2-selection--single .select2-selection__arrow {
				top: 6px;
				right: 5px;
				pointer-events: none;
			}
			& .select2-results__group {
				font-weight: bold;
			}
			& #filter_buttons {
				@include respond(small) {
					padding: 20px 0 0 0 !important;
				}
				& button {
					width: 100%;
					font-size: 0.7em;
					text-transform: none;
					&:focus {
						color: $gac-grey6;
					}
				}
			}
		}
	}
	& #kits_list_wrapper {
		& .edit-kit-button,
		.kit-settings-button {
			cursor: pointer;
		}
		& .uk-grid-small {
			padding-left: 5px !important;
			@include respond(small) {
				padding-left: 0 !important;
			}
		}
		margin: 0;
		max-width: 1500px;
		& .asset_grid {
			&#mobile_grid {
				& .asset_wrapper {
					&:nth-child(odd) {
						padding-right: 2px;
					}
					&:nth-child(even) {
						padding-left: 2px;
					}
					& .media_image_wrapper {
						height: 120px;
					}
				}
			}

			& .asset_wrapper {
				&[data-type="PDF"] {
					& .media_image_wrapper {
						text-align: center;
						& img {
							box-shadow: 10px 10px 30px -20px $gac-black;
							height: 100% !important;
							width: auto !important;
							position: relative;
							display: block;
						}
					}
				}
			}
			// these classes are only in the desktop grids
			&#grid2 {
				// margin-bottom: 100px;
			}
			& .large {
				height: 720px;
			}
			& .small {
				height: 320px;
			}
		}
		& .metadata_container {
			line-height: 100%;
			margin: 5px 0 0;
			@include respond(small) {
				line-height: initial;
			}
			& .image_name {
				font-size: 0.7em;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				@include respond(small) {
					max-width: 65%;
					font-size: 1em;
				}
				& .uk-icon {
					cursor: pointer;
				}
			}
			& .link_wrapper {
				font-size: 0.6em;
				margin-top: 3px;
				@include respond(small) {
					font-size: 0.7em;
				}
			}
			.settings-drop {
				left: 50% !important;
				transform: translateX(-50%);
				@include respond(small) {
					left: inherit !important;
					transform: none;
				}
			}
		}
		& .pagination_wrapper {
			margin-bottom: 0;
			@include respond(small) {
				margin-bottom: 50px;
			}
		}
	}
}
