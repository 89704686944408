#header {
	@include var(background, light-block-bg);
	@include var(color, light-block-fg);
	@include var(border-color, light-block-fg);

	border-bottom: 1px solid $gac-grey4;
	& nav {
		& #cart_count {
			font-size: 0.6em;
		}
		& logo {
			margin: 0 auto;
			width: auto;
			& svg {
				width: 150px;
				height: auto;
				@include respond(small) {
					width: 200px;
				}
			}
			& .logo_text h1,
			.logo_text .uk-h1 {
				width: 100%;
				font-size: 1.2em !important;
				line-height: unset;
				margin: 0;
				color: $gac-blue;
				@include respond(small) {
					font-size: 1.9em !important;
				}
			}
			& a:hover,
			.uk-link:hover {
				text-decoration: none;
			}
		}
		& #cart_counter {
			& a {
				text-decoration: none;
			}
			&:hover {
				border-bottom: 1px solid $gac-blue;
			}
		}
		.uk-navbar-nav {
			& li a,
			a {
				&:hover {
					color: $gac-grey8 !important;
				}
			}
		}
		& #right_nav {
			& #cart_counter {
				margin: 0 10px 0 -10px;
			}
			& #right_ear {
				& li:last-child {
					@include respond(small) {
						padding: 0 70px 0 0;
					}
				}
				& #language_switcher_wrapper {
					& #world_icon {
						@include respond(small) {
							padding-left: 15px;
							border-left: 1px solid;
						}
					}
				}
				& #language_drop {
					padding: 5px 10px;
					min-width: auto;
					max-width: 100px;
					font-size: 0.8em;
					left: unset !important;
					right: 10px;
					& li {
						margin-top: 0 !important;
					}
				}
			}
		}
	}
}

#search_drop {
	left: 0 !important;
	background-color: $gac-white;
	width: 100vw;
	margin: 0 auto;
	& form {
		display: block;
		margin: 0 auto;
		width: 90vw;
		border-bottom: 1px solid $gac-black;
		@include respond(small) {
			width: 50vw;
		}
		& input {
			font-size: 0.7em;
			@include respond(small) {
				font-size: 1em;
			}
		}
	}
}

#menu {
	& #menu_close {
		cursor: pointer;
	}
	& .uk-offcanvas-bar {
		width: 100vw;
		@include respond(small) {
			width: 25vw;
		}
	}
	& .uk-padding-large {
		padding: 50px;
	}
	& .uk-dropdown {
		box-shadow: none;
	}
	& #admin_help_link {
		cursor: pointer;
	}
	& #language_drop {
		& ul {
			padding: 5px 20px !important;
			margin: 10px 0 !important;
			& li {
				margin-top: 0;
			}
		}
	}
}
