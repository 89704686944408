.bg {
    &.grey1 {
        background-color: $gac-grey1;
    }
    &.grey2 {
        background-color: $gac-grey2;
    }
    &.grey3 {
        background-color: $gac-grey3;
    }
    &.grey4 {
        background-color: $gac-grey4;
    }
    &.grey5 {
        background-color: $gac-grey5;
    }
    &.grey6 {
        background-color: $gac-grey6;
    }
    &.grey7 {
        background-color: $gac-grey7;
    }
    &.grey8 {
        background-color: $gac-grey8;
    }
    &.grey9 {
        background-color: $gac-grey9;
    }
    &.grey10 {
        background-color: $gac-grey10;
    }
    &.grey11 {
        background-color: $gac-grey11;
    }
    &.grey12 {
        background-color: $gac-grey12;
    }
    &.white {
        background-color: $gac-white;
    }
    &.blue {
        background-color: $gac-blue;
    }
    &.steel {
        background-color: $gac-steel;
    }
    &.teal {
        background-color: $gac-teal;
    }
    &.orange {
        background-color: $gac-orange;
    }
    &.slate {
        background-color: $gac-slate;
    }
    &.sand {
        background-color: $gac-sand;
    }
    &.brown {
        background-color: $gac-brown;
    }
    &.black {
        background-color: $gac-black;
    }
    &.gold {
        background-color: $gac-gold;
    }
    &.peri {
        background-color: $gac-peri;
    }
    &.utility-blue {
        background-color: $gac-utility-blue;
    }
    &.utility-green {
        background-color: $gac-utility-green;
    }
    &.utility-yellow {
        background-color: $gac-utility-yellow;
    }
    &.utility-orange {
        background-color: $gac-utility-orange;
    }
    &.utility-red {
        background-color: $gac-utility-red;
    }
    &.utility-blue {
        background-color: $gac-utility-blue;
    }
}
