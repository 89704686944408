// most of these are to do with customizing 
// the look of select2 library's handling of
// dropdown select lists
.select2-container {
    // width: 100% !important;
    & .select2-selection {
        border: 0 !important;
    }
    & .select2-results__option {
        line-height: 150% !important;   
    }
}
.select2-dropdown {
        font-size: .8em;
        width: auto;
        border: 0;
    }
