button,
.button,
.uk-button {
	line-height: 1.2 !important;
	height: 50px;
}
.button {
	font-size: 1em;
	& .all_releases_text {
		font-size: 1.15rem;
		padding: 0 0.6em;
		&:hover {
			color: $gac-grey9;
		}
	}
	& a {
		text-decoration: none;
		border: none !important;
		&:hover {
			color: $gac-grey9;
		}
	}
}
[data-button] {
	cursor: pointer !important;
	text-decoration: none;
	& a {
		text-decoration: none;
		&:hover {
			color: $gac-grey9;
		}
	}
	&:hover {
		color: $gac-grey9;
	}
	& .disabled_button {
		opacity: 0.5;
		cursor: wait;
	}
}
.button_anchor {
	&:hover {
		text-decoration: none;
	}
}
