#kit_wrapper {
    & .uk-first-column {
        padding-left: 0 !important;
    }
    & #kit_header {
        padding: 40px 0;
    }
    & #kit_releases_wrapper {
        max-width: 1500px;
        padding: 0 20px 50px !important;
        @include respond(small) {
            padding: 0 50px 100px !important;
        }
        & .asset_wrapper {
            & .release_headline {
                font-size: 0.8em;
                line-height: 140%;
            }
            & .release_link {
                margin-top: 5px;
                font-size: 0.8em;
                line-height: 140%;
            }
            & .media_image_wrapper {
                width: 100%;
                height: 200px;
                @include respond(small) {
                    height: 280px;
                }
            }
        }
    }
    & #kit_documents_section {
        background-color: $gac-grey2;
        @include respond(small) {
            padding: 130px;
        }
        & #kit_documents_title {
            max-width: 1500px;
            font-size: 0.7em;
            padding: 0 20px 20px;
            line-height: 160%;
            @include respond(small) {
                font-size: 0.8em;
                padding: 0 50px 50px;
            }
        }
        & #kit_documents_wrapper {
            max-width: 1500px;
            padding: 0 20px;
            @include respond(small) {
                padding: 0 50px;
            }
            & + * {
                margin-left: 0 !important;
                padding-left: 0 !important;
            }
            & .doc_wrapper {
                padding-top: 10px;
                padding-bottom: 10px;
                @include respond(small) {
                    padding-top: 0px;
                    padding-bottom: 0px;
                }
                & hr {
                    height: 1px;
                    border-top: 1px solid $gac-black;
                }
                & .doc_name {
                    font-size: 0.8em;
                    line-height: 150%;
                    width: 100%;
                    @include respond(small) {
                        max-width: 70%;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                }
                & .doc_link_wrapper {
                    & .doc_link {
                        font-size: 0.8em;
                    }
                }
            }
        }
    }
}
