.block__header {
	margin: 60px 0 !important;
	position: relative;
	font-size: 0.966875rem;
	font-family: $font-family-heading !important;
	&:lang(zh-hans),
	:lang(zh-hant) {
		font-family: $font-family-heading-cn !important;
		font-weight: 400;
	}
	text-transform: uppercase;
	line-height: 160%;
	@include respond(small) {
		margin: 120px 0 !important;
		font-size: 1.4rem;
	}
}
