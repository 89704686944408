#helpModalBody {
    .text-green {
        color: $gac-utility-green;
    }
    .text-red {
        color: $gac-utility-red;
    }
    .text-orange {
        color: $gac-orange;
    }
    .uk-accordion-title {
        cursor: pointer;
    }
}
