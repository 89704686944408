#in_the_news_wrapper,
#gic_list {
    & .gin_wrapper,
    .gic_wrapper {
        & .gin_date,
        .gic_date {
            font-size: 0.5em;
            line-height: 160%;
            @include respond(small) {
                font-size: 0.6em;
            }
        }
        & .gin_publication,
        .gic_publication {
            font-size: 0.8em;
            line-height: 130%;
            margin-bottom: 5px;
            @include respond(small) {
                font-size: 1.2em;
                margin-bottom: 10px;
            }
        }
        & .gin_headline,
        .gic_headline {
            line-height: 140%;
            font-size: 0.5em;
            @include respond(small) {
                font-size: 0.9em;
            }
        }
    }
}
