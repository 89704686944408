#showing_wrapper {
    font-size: 0.5em;
    max-width: 1500px;
    @include respond(small) {
        font-size: 0.8em;
        margin-top: 50px;
    }
}
#showing_divider {
    max-width: 1500px;
    border-top: 1px solid $gac-grey6;
    margin-bottom: 25px;
    margin: 0 0 25px 0;
    width: calc(100% - 30px);
    @include respond(small) {
        max-width: 1470px;
        margin: 0 0 50px 0;
    }
}
