#kit_editor {
	margin: 30px 0 50px 0;
	padding: 10px;
	line-height: 160%;
	background-color: $gac-grey3;
	& #kit_form {
		width: 100%;
		& .error {
			color: $gac-utility-orange;
		}
		& #found_releases_wrapper {
			background-color: $gac-grey2;
			max-height: 200px;
			overflow: auto;
			font-size: 0.7em;
			line-height: 140%;
		}
		& #image_drop_area,
		#document_drop_area {
			padding: 10px;
			position: relative;
			& #upload_message {
				padding: 10px;
			}
		}
		& #image_manager,
		#thumb_picker,
		#selected_thumb {
			.thumb_wrapper {
				width: 100%;
				max-width: 350px;
				& .thumbpicker_thumb {
					width: 100%;
					max-width: 300px;
					height: 200px;
				}
			}
		}
		#selected_thumb {
			& .thumb_wrapper {
				background-color: $gac-sand;
				padding: 20px;
				@include respond(small) {
					padding: 40px;
				}
				& .thumbpicker_thumb {
					border: 1px solid $gac-white;
				}
			}
		}
		#selected_documents {
			background-color: $gac-sand;
			padding: 20px;
			@include respond(small) {
				padding: 40px;
			}
			& .document_wrapper {
				& .thumbpicker_thumb {
					border: 1px solid $gac-blue;
				}
			}
		}
		& .thumb_name,
		.thumb_langname {
			font-size: 0.7em;
			max-width: 200px;
			line-height: 150%;
			height: 40px;
			margin: 0 0 5px 0;
			&[data-editable="true"] {
				cursor: pointer;
			}
			& .doc_name_ok {
				color: $gac-utility-green;
				cursor: pointer;
			}
			& .doc_name_cancel {
				color: $gac-utility-red;
				cursor: pointer;
			}
		}
		& #selected_documents,
		#document_manager {
			& .thumbpicker_thumb {
				width: 200px;
				height: 300px;
			}
		}
		& .thumbpicker_thumb {
			cursor: pointer;
			width: 300px;
			height: 200px;
		}
		& .selected_thumb {
			& .selected_remove {
				cursor: pointer;
			}
		}
	}
	& #kitFormMsg {
		background-color: rgba($gac-utility-orange, 0.25);
		color: $gac-utility-orange;
		& .underline {
			border-color: $gac-utility-orange;
		}
	}
}
