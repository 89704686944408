#search_wrapper {
	& #showing_wrapper {
		font-size: 0.5em;
		border-bottom: 1px solid $gac-grey5;
		@include respond(small) {
			font-size: 0.8em;
			margin-top: 50px;
			margin-bottom: 50px;
		}
	}
	& .inactive {
		color: $gac-grey5;
	}
	& .release_wrapper {
		border-bottom: 1px solid $gac-grey4;
		padding: 35px 0 50px 0 !important;
		&:first-child {
			padding-top: 25px !important;
		}
		& .metadata_wrapper {
			height: auto;
			width: fit-content;
			font-size: 0.75em;
			padding: 20px;
			margin: 0 0 0 40px;
			text-transform: initial;
			text-align: right;
			background-color: $gac-grey2;
			& .metadata_row {
				margin: 0 0 25px 0;
				& .metadata_label {
					margin-left: 10px;
					font-weight: 400;
					&:lang(zh-hans),
					:lang(zh-hant) {
						font-family: $font-family-cn !important;
					}
				}
				& .pubbed_wrapper,
				.tags_wrapper,
				.editors_wrapper {
					& .open {
						border: 1px solid $gac-grey8;
					}
				}
				.tags_wrapper,
				.editors_wrapper {
					.uk-icon {
						color: $gac-grey6;
					}
				}
				& [data-toggle] {
					position: relative;
					& .toggle-spinner {
						top: 10px;
						left: 10px;
						position: absolute;
						@include respond(small) {
							top: 5px;
						}
					}
					& .toggle-icon.pending {
						opacity: 0.25;
					}
				}
				& [data-lang] {
					margin: 0 7px;
					font-size: 1.4em;
					cursor: pointer;
					display: inline-block;
					width: 30px;
					@include respond(small) {
						margin: 0 5px;
						font-size: inherit;
					}
				}
				@include respond(small) {
					margin: 0;
					line-height: 175%;
				}
			}
			& div.metadata_row:last-child {
				margin: 0;
			}

			& .metaMsgTop,
			.metaMsgBottom {
				margin: 0 0 10px 10px;
				text-align: left;
				background-color: transparent;
				font-size: 1.2em;
			}
		}
		& .content_wrapper {
			& .pubDate {
				font-size: 0.4em;
				@include respond(small) {
					font-size: 0.75em;
					padding-left: 40px;
				}
			}
			& .catLinks {
				font-size: 0.5em;
				color: $gac-grey8;
				& a {
					color: $gac-grey8;
				}
				@include respond(small) {
					font-size: 0.65em;
					color: $gac-black;
					& a {
						color: $gac-black;
					}
				}
			}
			& .headline {
				font-size: 0.7em;
				line-height: 160%;
				& a {
					&:hover {
						text-decoration: none;
						color: $gac-grey9;
					}
				}
				@include respond(small) {
					font-size: 1.2em;
					max-width: 900px;
				}
			}
			& .summary {
				font-size: 0.65em;
				line-height: 170%;
				& [data-button="edit button"] {
					margin: 0 0 0 10px;
				}
				@include respond(small) {
					font-size: 1em;
					line-height: 160%;
					margin-bottom: 20px;
					max-width: 900px;
				}
			}
			& .read_more_link {
				font-size: 0.55em;
				& a {
					border-bottom: 1px solid;
					text-decoration: none;
					&:hover {
						color: $gac-grey8;
					}
				}
				@include respond(small) {
					font-size: 0.9em;
				}
			}
		}
	}
}
