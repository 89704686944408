#backgrounder_links {
    #saveBgOrderButton {
        margin-bottom: 20px;
    }
    .backgrounder_wrapper {
        margin-bottom: 10px;
        @include respond(small){
            margin-bottom: 0px;
        }
        & .backgrounder_name {
            font-size: .8em;
            line-height: 120%;
            @include respond(small){
                font-size: 1em;
                line-height: 170%;
            }
        }
        & .backgrounder_link {
            & a {
                font-size: .7em;
                @include respond(small){
                    font-size: .8em;
                }
            }
        }
        & .separator {
            border-top: 1px solid $gac-white;
        }
    }
}