.mce-content-body {
	padding: 10px;
	font-size: 1.8em;
	& .news_video_embed {
		width: 100%;
		@include respond(small) {
			width: 60%;
			margin: 30px auto;
		}
		& .download_link {
			text-align: right;
			font-size: 0.6em;
			margin-bottom: 10px;
		}
	}
}
#admin_controls {
	margin-top: 50px;
}
#news_editor {
	margin: 30px 0 50px 0;
	padding: 10px;
	line-height: 160%;
	border-bottom: 4px solid #e7e7e7;
	& .underline {
		border-bottom: 1px solid $gac-grey8;
	}
	& .pointer {
		cursor: pointer !important;
	}
	& iframe {
		width: 100%;
		height: 100vh;
		border: none;
		overflow: auto;
		& .search-container-header-title {
			display: none;
		}
	}
	& .form_messaging {
		padding: 10px;
		min-height: 80px;
		text-align: left;
		display: none;
		& [data-button] {
			margin: 0 10px 0 0;
		}
	}
	& .form_messaging.error {
		background-color: rgba($gac-utility-orange, 0.25);
		border: 1px solid $gac-utility-orange;
	}
	& #news_form {
		width: 100%;
		& .field[data-field="tags"] {
			cursor: pointer;
			width: 300px;
			max-width: 300px;
		}
		& .field {
			text-align: left;
			margin: 0 0 10px 0;
			& input,
			textarea,
			select,
			.select2-selection {
				&[name="headline"],
				&[name="subhead"],
				&[name="summary"] {
					width: 100%;
				}
			}
			& [data-button="image upload button"] {
				color: $gac-grey8;
				background-color: transparent;
			}
			& [data-section="cart form actions"] {
				float: right;
			}
			& [data-button="add tag"] {
				float: right;
				margin: 0 0 0 5px;
				padding: 2px 10px;
			}
			input[name="createdReadable"] {
				min-width: 205px;
			}
		}
		& #image_manager {
			margin: 30px 0 0 0;
			& .image_card {
				max-width: 450px;
				margin-top: 40px;
				background-color: #ffffff;
				padding: 10px;
				& .image_header {
					font-size: 0.8em;
				}
				& .images {
					width: 100%;
					max-width: 450px;
					& .thumbnail_wrapper {
						width: 100%;
						height: 100%;
						margin: 10px auto;
						& img {
							width: 100%;
							height: auto !important;
						}
					}
					& .thumbnail_preview {
						margin: 10px auto;
						width: 100%;
						height: 135px;
						& img {
							object-fit: cover;
							width: 100%;
							height: 100%;
						}
					}
				}
				& [data-button="remove image"] {
					margin-left: 10px;
				}
				& .dam_card_meta_wrapper {
					font-size: 0.7em;
					line-height: 1.3;
				}
			}
			& .image_card.preview {
				width: 100%;
			}
		}
		& #image_drop_area.drag_entered {
			box-shadow: 0 0 20px -10px $gac-blue;
		}
		& #footer,
		#newsBody {
			padding: 10px;
			border: 1px solid $gac-grey3;
			cursor: pointer;
			& p {
				margin: 0 0 0.5em 0;
			}
			& .footer-header {
				text-transform: uppercase;
			}
		}
		& #newsBody {
			min-height: 70px;
		}
		& .disabled_button {
			opacity: 0.5;
			cursor: wait;
		}
		#dam_image_browser {
			& .dam_browser_field {
				margin: 10px 0 20px 0;
				border-bottom: 1px solid $gac-grey2;
				padding-bottom: 10px;
				& [data-expand-target] {
					cursor: pointer;
				}
				& .dam_browser_values_wrapper {
					display: auto;
					& .dam_cards_wrapper {
						width: 100%;
						& .uk-tooltip.uk-active {
							width: 100% !important;
						}
						& .dam_card {
							width: 100%;
							& .dam_card_thumb_wrapper {
								margin: 20px 0 0 0;
								width: 100%;
								height: auto;
								@include respond(small) {
									width: 250px;
									height: 150px;
								}
								& img {
									width: 100%;
									height: 100%;
									-o-object-fit: cover;
									object-fit: cover;
								}
							}
							& .dam_card_select_wrapper {
								display: -webkit-box;
								display: -ms-flexbox;
								display: flex;
								-webkit-box-orient: horizontal;
								-webkit-box-direction: normal;
								-ms-flex-direction: row;
								flex-direction: row;
								-webkit-box-pack: start;
								-ms-flex-pack: start;
								justify-content: flex-start;
								-webkit-box-align: center;
								-ms-flex-align: center;
								align-items: center;
								& label {
									width: auto;
								}
								& input {
									margin: 0 0 0 5px;
								}
							}
							@include respond(small) {
								width: 250px;
							}
						}
						& .dam_card:last-child {
							@include respond(small) {
								margin-right: 0;
							}
						}
					}
					& .dam_cards_wrapper.closed {
						display: none;
					}
				}
				& .dam_browser_values_wrapper.closed {
					display: none;
				}
			}
		}
	}
}
.uk-sortable-drag.image_card {
	max-width: 380px;
	margin-top: 40px;
	& .image_header {
		font-size: 0.8em;
	}
	& .thumbnail_wrapper {
		width: 100%;
		height: 200px;
		overflow: hidden;
		margin: 10px auto;
		& img {
			width: 100%;
			height: auto !important;
		}
	}
	& [data-button="remove image"] {
		margin-left: 10px;
	}
	& .dam_card_meta_wrapper {
		font-size: 0.7em;
	}
}
